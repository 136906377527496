import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Background from "../../images/SectionOne.png"
import FormContact from "../FormContact/FormContact"

const SectionOne = props => {
  return (
    <>
      <Grid
        container
        css={css`
          width: 100%;
          height: 912px;
          background-image: url(${Background});
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: 100% 30%;
          @media (max-width: 768px) {
            background-size: auto 100%;
            background-position: 60% 0%;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
          `}
        >
          <div
            css={css`
              width: 40%;
              height: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              @media (max-width: 768px) {
                width: 80%;
              }
            `}
          >
            <Typography
              css={css`
                color: #1b5196;
                font-size: 40px !important;
                letter-spacing: 6.62px;
                opacity: "1";
              `}
            >
              EXPERTOS
            </Typography>
            <Typography
              css={css`
                font-size: 32px !important;
                letter-spacing: 0px;
                color: #ffffff;
                text-align: center;
                text-shadow: 0px 3px 6px #000;
              `}
            >
              EN ADMINISTRACIÓN DE <br />
              RECURSOS HUMANOS
            </Typography>
            <FormContact type="Button" />
          </div>
        </div>
      </Grid>
    </>
  )
}

export default SectionOne
