import React from "react"
import { css } from "@emotion/core"

export default function CardDescription(props) {
  const { icon } = props
  return (
    <div
      css={css`
        width: 30%;
        height: 320px;
        display: flex;
        align-items: center;
        box-shadow: 0px 2px 5px 1px #a8a8a8;
        background-color: #fff;
        flex-direction: column;
        @media (max-width: 768px) {
          width: 80%;
          height: 330px;
          margin: 50px;
        }
      `}
    >
      <div
        css={css`
          width: 120px;
          height: 120px;
          background-color: #fff;
          margin-top: -50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        `}
      >
        <img
          css={css`
            width: 60%;
            height: 60%;
          `}
          src={icon}
          alt={icon}
        />
      </div>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 60px);
          display: flex;
          align-items: center;
          flex-direction: column;
        `}
      >
        {props.children}
      </div>
    </div>
  )
}
