import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import OficerMexico from "../../images/OficerMexico.png"
import CorrespondentsMexico from "../../images/CorrespondentsMexico.png"

import FormContact from "../FormContact/FormContact"

export default function SectionSix() {
  return (
    <>
      <section id="Cobertura">
        <Grid
          container
          alignItems={"center"}
          justify={"center"}
          direction={"column"}
          css={css`
            width: 100%;
            height: 912px;
            min-height: 912px;
            @media (max-width: 768px) {
              height: auto;
              padding-top: 50px;
              padding-bottom: 30px;
            }
          `}
        >
          <Typography
            css={css`
              color: #1b5196;
              font-size: 40px !important;
              letter-spacing: 6.62px;
              opacity: "1";
            `}
          >
            COBERTURA
          </Typography>
          <hr
            css={css`
              width: 5%;
              border-top: 3px solid #1b5196;
              margin-top: 10px;
              margin-bottom: 30px;
              @media (max-width: 768px) {
                width: 15%;
                margin-top: 20px;
              }
            `}
          />
          <Typography
            css={css`
              width: 75%;
              font-size: 20px !important;
              opacity: "1";
              text-align: center;
              line-height: 23px !important;
              @media (max-width: 768px) {
                margin-top: 0 !important;
                width: 85%;
              }
            `}
          >
            Cobertura en México. Contamos con oficinas en Monterrey, Ciudad de
            México, Guadalajara y Mérida, así como corresponsales en varios
            estados más.
          </Typography>
          <div
            css={css`
              width: 70%;
              display: flex;
              justify-content: space-between;
              margin-top: 30px !important;
              @media (max-width: 768px) {
                width: 85%;
                flex-direction: column;
              }
            `}
          >
            <div
              css={css`
                width: 45%;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              <img
                css={css`
                  width: 100%;
                `}
                src={OficerMexico}
                alt="OficerMexico"
              />
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 18px !important;
                  text-align: center;
                  margin-top: 20px !important;
                `}
              >
                Monterrey • Ciudad de México • Guadalajara • Mérida
              </Typography>
            </div>
            <div
              css={css`
                width: 45%;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media (max-width: 768px) {
                  width: 100%;
                  margin-top: 50px;
                }
              `}
            >
              <img
                css={css`
                  width: 100%;
                `}
                src={CorrespondentsMexico}
                alt="OficerMexico"
              />
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 18px !important;
                  text-align: center;
                  margin-top: 20px !important;
                `}
              >
                Mexicalli • Tijuana • Culiacan • Chihuahua • Cd. Juárez • Puerto
                Vallarta • Laredo • Cd. Victoria • Sabina, Coahuila • Saltillo •
                S.L.P • Torreón • Monclova • Los Mochis • Puebla • Cancún
              </Typography>
            </div>
          </div>
          <FormContact type="Button" />
        </Grid>
      </section>
    </>
  )
}
