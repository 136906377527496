import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Background from "../../images/SectionThree.png"
import BackgroundResponsive from "../../images/SectionThreeResponsive.png"

const SectionOne = props => {
  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justify={"center"}
        css={css`
          width: 100%;
          height: 912px;
          background-image: url(${Background});
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: 100% 30%;
          @media (max-width: 768px) {
            background-size: 100% 100%;
            background-position: 60% 0%;
            background-image: url(${BackgroundResponsive});
          }
        `}
      >
        <div
          css={css`
            width: 65%;
            height: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;
          `}
        >
          <Typography
            css={css`
              color: #1b5196;
              font-size: 40px !important;
              letter-spacing: 6.62px;
              opacity: "1";
              text-align: center;
            `}
          >
            GRUPO ENLACCE ES...
          </Typography>
          <hr
            css={css`
              width: 5%;
              border-top: 3px solid #1b5196;
              @media (max-width: 768px) {
                width: 15%;
              }
            `}
          />
          <Typography
            css={css`
              font-size: 20px !important;
              opacity: "1";
              text-align: center;
              line-height: 23px !important;
              width: 70%;
              margin-top: 50px !important;
              @media (max-width: 768px) {
                margin-top: 0 !important;
                width: 100%;
              }
            `}
          >
            Una firma que consolida a expertos en distintas áreas,
            permitiéndonos ofrecer servicios de administración de Recursos
            Humanos con diferentes alternativas que sean acordes a sus
            necesidades y cumpliendo los mandatos jurídicos dentro de un marco
            de transparencia, acorde a las obligaciones laborales y fiscales que
            marcan la ley.
          </Typography>
          {/* <div
            css={css`
              width: 40%;
              height: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              @media (max-width: 768px) {
                width: 80%;
              }
            `}
          >
            <Typography
              css={css`
                color: #1b5196;
                font-size: 40px !important;
                letter-spacing: 6.62px;
                opacity: "1";
              `}
            >
              EXPERTOS
            </Typography>
            <Typography
              css={css`
                font-size: 32px !important;
                letter-spacing: 0px;
                color: #ffffff;
                text-align: center;
                text-shadow: 0px 3px 6px #000;
              `}
            >
              EN ADMINISTRACIÓN DE <br />
              RECURSOS HUMANOS
            </Typography>
            <Button
              variant="contained"
              css={css`
                border-radius: 50px !important;
                margin-top: 40px !important;
                width: 182px;
                height: 45px;
                font-size: 20px !important;
                display: block;
                background-color: #1b5196 !important;
                color: #fff !important;
              `}
            >
              CONTÁCTANOS
            </Button>
          </div> */}
        </div>
      </Grid>
    </>
  )
}

export default SectionOne
