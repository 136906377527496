import React from "react"
import Helmet from "react-helmet"
import SectionOne from "../components/InformativeSection/SectionOne"
import SectionTwo from "../components/InformativeSection/SectionTwo"
import SectionThree from "../components/InformativeSection/SectionThree"
import SectionFour from "../components/InformativeSection/SectionFour"
import SectionFive from "../components/InformativeSection/SectionFive"
import SectionSix from "../components/InformativeSection/SectionSix"

const IndexPage = () => (
  <>
    <Helmet>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
    </Helmet>
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <SectionFour />
    <SectionFive />
    <SectionSix />
  </>
)

export default IndexPage
