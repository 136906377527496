import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Background from "../../images/SectionFive.png"
import FormContact from "../FormContact/FormContact"

export default function SectionFive() {
  return (
    <>
      <section id="Fortalezas">
        <Grid
          container
          alignItems={"center"}
          justify={"center"}
          css={css`
            width: 100%;
            height: 912px;
            min-height: 912px;
            background-image: url(${Background});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 100% 30%;
            @media (max-width: 768px) {
              background-size: auto 100%;
              background-position: 80% 0%;
            }
          `}
        >
          <div
            css={css`
              width: 100%;
              height: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 768px) {
                align-items: center;
                justify-content: flex-start;
              }
            `}
          >
            <div
              css={css`
                width: 40%;
                height: 60%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @media (max-width: 768px) {
                  align-items: flex-start;
                  width: 50%;
                  padding-left: 15px;
                }
              `}
            >
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 40px !important;
                  letter-spacing: 6.62px;
                  opacity: "1";
                `}
              >
                FORTALEZAS
              </Typography>
              <hr
                css={css`
                  width: 5%;
                  border-top: 3px solid #1b5196;
                  margin-top: 10px;
                  margin-bottom: 30px;
                  @media (max-width: 768px) {
                    width: 15%;
                    margin-left: 0px;
                    margin-top: 20px;
                  }
                `}
              />
              <Typography
                css={css`
                  font-size: 20px !important;
                  opacity: "1";
                  text-align: center;
                  line-height: 23px !important;
                  width: 95%;
                  @media (max-width: 768px) {
                    font-size: 18px !important;
                    margin-top: 0 !important;
                    width: 85%;
                    text-align: left;
                  }
                `}
              >
                Nuestras áreas de recursos humanos, consultorías legal y fiscal
                son una herramienta que complementa las necesidades de cualquier
                empresa.
              </Typography>
              <Typography
                css={css`
                  font-size: 20px !important;
                  opacity: "1";
                  text-align: center;
                  line-height: 23px !important;
                  margin-top: 20px !important;
                  width: 93%;
                  @media (max-width: 768px) {
                    font-size: 18px !important;
                    width: 85%;
                    text-align: left;
                  }
                `}
              >
                Además, establecemos una relación comercial bajo el esquema de un
                contrato de prestación de servicios profesionales y con la
                intención de proteger a nuestros clientes celebramos un convenio
                de confidencialidad.
              </Typography>
              <FormContact type="Button"/>
            </div>
          </div>
        </Grid>
      </section>
    </>
  )
}
