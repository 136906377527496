import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"

const SectionOne = props => {
  return (
    <>
      <section id="Enlacce">
        <Grid
          container
          css={css`
            width: 100%;
            height: 912px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={css`
              width: 68%;
              height: 40%;
              display: flex;
              align-items: center;
              flex-direction: column;
              @media (max-width: 768px) {
                width: 90%;
              }
            `}
          >
            <Typography
              css={css`
                color: #1b5196;
                font-size: 40px !important;
                letter-spacing: 6.62px;
                opacity: "1";
                text-align: center;
              `}
            >
              Nuestros servicios se enfocan en las áreas fiscal, contable y
              laboral.
            </Typography>
            <hr
              css={css`
                width: 5%;
                border-top: 3px solid #1b5196;
                margin-top: 10px;
                @media (max-width: 768px) {
                  width: 15%;
                  margin-top: 20px;
                }
              `}
            />
            <Typography
              css={css`
                width: 70%;
                color: #1b5196;
                font-size: 32px !important;
                opacity: "1";
                text-align: center;
                font-style: italic;
                margin-top: 30px !important;
                @media (max-width: 768px) {
                  width: 100%;
                  margin-top: 10px !important;
                }
              `}
            >
              Nos adaptamos siempre a las necesidades y características de
              nuestros clientes
            </Typography>
          </div>
        </Grid>
      </section>
    </>
  )
}

export default SectionOne
