import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import IconPerson from "../../images/IconPerson.png"
import IconBalance from "../../images/IconBalance.png"
import CardDescription from "../CardDescription"

const SectionFour = props => {
  return (
    <>
    <section id="Areas_Asesoria">
      <Grid
        container
        alignItems={"center"}
        justify={"center"}
        direction={"column"}
        css={css`
          width: 100%;
          height: 912px;
          background-color: #e6f1ff;
          @media (max-width: 768px) {
            min-height: 912px;
            height: auto;
            justify-content: space-around !important;
          }
        `}
        
      >
        <div
          css={css`
            width: 100%;
            height: 20%;
            display: flex;
            align-items: center;
            flex-direction: column;
            @media (max-width: 768px) {
              margin-top: 100px;
              margin-bottom: 70px;
            }
          `}
        >
          <Typography
            css={css`
              color: #1b5196;
              font-size: 40px !important;
              letter-spacing: 6.62px;
              opacity: "1";
              @media (max-width: 768px) {
                width: 60%;
                text-align: center;
              }
            `}
          >
            ÁREAS DE ASESORÍA
          </Typography>
          <hr
            css={css`
              width: 5%;
              border-top: 3px solid #1b5196;
              margin-top: 10px;
              @media (max-width: 768px) {
                width: 15%;
                margin-top: 20px;
              }
            `}
          />
        </div>
        <div
          css={css`
            width: 90%;
            display: flex;
            justify-content: space-between;
            @media (max-width: 768px) {
              flex-direction: column;
              height: auto;
              align-items: center;
              margin-bottom: 100px;
            }
          `}
        >
          <CardDescription icon={IconPerson}>
            <Typography
              css={css`
                color: #1b5196;
                font-size: 30px !important;
                width: 70%;
                text-align: center;
              `}
            >
              RECURSOS HUMANOS
            </Typography>
            <Typography
              css={css`
                font-size: 20px !important;
                text-align: center;
                line-height: 23px !important;
                width: 90%;
                @media (max-width: 768px) {
                  width: 85%;
                }
              `}
            >
              Su personal será contratado por nuestra empresa cumpliendo con las
              obligaciones patronales, tales como las relativas a seguridad
              social e incluyendo beneficios extra legales para mejorar las
              condiciones de los trabajadores.
            </Typography>
          </CardDescription>
          <CardDescription icon={IconBalance}>
            <Typography
              css={css`
                color: #1b5196;
                font-size: 30px !important;
                width: 70%;
                text-align: center;
              `}
            >
              CONSULTORÍA FISCAL
            </Typography>
            <Typography
              css={css`
                font-size: 20px !important;
                text-align: center;
                line-height: 23px !important;
                width: 95%;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              Contamos con un equipo de abogados especialistas en el área
              laboral para ofrecerle sin costo: Asesoría jurídica, Gestiones
              legales por bajas de empleados y Gestiones ante autoridades de
              trabajo.
            </Typography>
          </CardDescription>
          <CardDescription icon={IconPerson}>
            <Typography
              css={css`
                color: #1b5196;
                font-size: 30px !important;
                width: 70%;
                text-align: center;
              `}
            >
              CONSULTORÍA LEGAL
            </Typography>
            <Typography
              css={css`
                font-size: 20px !important;
                text-align: center;
                line-height: 23px !important;
                width: 90%;
                @media (max-width: 768px) {
                  width: 100%;
                }
              `}
            >
              Diseñamos un modelo de trabajo que permite asegurar el debido
              cumplimiento de las obligaciones fiscales.
            </Typography>
          </CardDescription>
        </div>
      </Grid>
      </section>
    </>
  )
}

export default SectionFour
